import { UserManager } from "oidc-client-ts";
import React, { useEffect } from "react";
import { SSO_OPTIONS } from "@services/authService";

export const SsoCallback = () => {
  useEffect(() => {
    new UserManager(SSO_OPTIONS).signinCallback();
  }, []);
  return <></>;
};
