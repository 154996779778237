import { UserLanguage, ThemeType, OtpContactType } from "@technis/shared";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";

export const TABS = {
  GENERAL: 0,
  RIGHTS: 1,
  ACCESSES: 2,
};

export enum Dashboard {
  LEGACY = "Legacy",
  // VISION = "Vision",
}

export type LanguageDropdownOption = {
  label: string;
  value: UserLanguage;
};

export const supportedLanguages = [
  {
    label: i18n.t(translation.languages.fr),
    value: UserLanguage.FR,
  },
  {
    label: i18n.t(translation.languages.en),
    value: UserLanguage.EN,
  },
  {
    label: i18n.t(translation.languages.it),
    value: UserLanguage.IT,
  },
];

export type ThemeDropdownOption = {
  label: string;
  value: ThemeType;
};

export const themeDropdownOptions = [
  {
    label: i18n.t(translation.theme.light),
    value: ThemeType.LIGHT,
  },
  {
    label: i18n.t(translation.theme.dark),
    value: ThemeType.DARK,
  },
];

export const getUserFormTabs = (onClick: (id: number) => void) => [
  {
    id: TABS.GENERAL,
    text: i18n.t(translation.users.generalInformation),
    onClick: () => onClick(TABS.GENERAL),
  },
  {
    id: TABS.RIGHTS,
    text: i18n.t(translation.users.rights),
    onClick: () => onClick(TABS.RIGHTS),
  },
  {
    id: TABS.ACCESSES,
    text: i18n.t(translation.users.accesses),
    onClick: () => onClick(TABS.ACCESSES),
  },
];

export const getOtpContactsForUser = ({ isEmailVerified, isPhoneVerified }: { isEmailVerified: boolean; isPhoneVerified: boolean }) =>
  Object.values(OtpContactType).filter((otpContact) => (otpContact === OtpContactType.EMAIL && isEmailVerified) || (otpContact === OtpContactType.PHONE && isPhoneVerified));
