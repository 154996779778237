import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ThemeContextProvider } from "../common/Theme";
import { ROUTES } from "./config";
import { NotFound } from "../views/NotFound/NotFound";
import { Login } from "../views/Login/Login";
import { ToastHandler } from "../components/ToastHandler";
import { ME_DATA_QUERY, MeQueryData } from "../services/userService";
import { getTranslationDetails } from "../redux/translation/translation.actions";
import { GlobalSearch } from "@views/GlobalSearch/GlobalSearch";
import { UsersSearch } from "@views/UsersSearch/UsersSearch";
import { LoggedRoute } from "./LoggedRoute";
import { MainLayout } from "@views/MainLayout";
import { InstallationsSearch } from "@views/InstallationsSearch/InstallationsSearch";
import { OrganizationsSearch } from "@views/OrganizationSearch/OrganizationSearch";
import { SsoCallback } from "@views/Login/SsoCallback";
import { SsoPopupCallback } from "@views/Login/SsoPopupCallback";

export const AppRoutes = () => {
  const { data: userData } = useQuery<MeQueryData>(ME_DATA_QUERY);
  const user = userData?.me;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTranslationDetails());
  }, []);

  return (
    <ThemeContextProvider setRoot>
      <BrowserRouter>
        <ToastHandler />
        <Routes>
          <Route path={ROUTES.SSO_CALLBACK} element={<SsoCallback />} />
          <Route path={ROUTES.SSO_POPUP_CALLBACK} element={<SsoPopupCallback />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route element={<MainLayout />}>
            <Route
              path={ROUTES.ROOT}
              element={
                <LoggedRoute>
                  <GlobalSearch />
                </LoggedRoute>
              }
            />
            <Route
              path={ROUTES.USERS}
              element={
                <LoggedRoute>
                  <UsersSearch />
                </LoggedRoute>
              }
            />
            <Route
              path={ROUTES.INSTALLATIONS}
              element={
                <LoggedRoute>
                  <InstallationsSearch user={user} />
                </LoggedRoute>
              }
            />
            <Route
              path={ROUTES.ORGANIZATIONS}
              element={
                <LoggedRoute>
                  <OrganizationsSearch />
                </LoggedRoute>
              }
            />
          </Route>
          <Route path={ROUTES.ALL} element={<Navigate to={user ? ROUTES.ROOT : ROUTES.LOGIN} />} />
          <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeContextProvider>
  );
};
