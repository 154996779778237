import { ApolloQueryResult, gql } from "@apollo/client";
import { client } from "./index";
import { ROUTES } from "@routes/config";
import { UserManagerSettings } from "oidc-client-ts";

export const SSO_OPTIONS: UserManagerSettings = {
  authority: process.env.SSO_AUTHORITY,
  client_id: process.env.SSO_CLIENT_ID,
  redirect_uri: `${window.location.origin}${ROUTES.SSO_CALLBACK}`,
  response_type: "code",
  scope: "openid email roles",
  response_mode: "fragment",
  popup_redirect_uri: `${window.location.origin}${ROUTES.SSO_POPUP_CALLBACK}`,
};

const LOGIN_QUERY = gql`
  query login($email: String!, $password: String!, $deviceId: String) {
    login(email: $email, password: $password, deviceId: $deviceId)
  }
`;

export const LOGIN_WITH_OTP_TOKEN_QUERY = gql`
  query loginWithOtpToken($otp: String!) {
    loginWithOtpToken(otp: $otp)
  }
`;

const RENEW_TOKEN_QUERY = gql`
  query renewToken {
    renew
  }
`;

const LOGIN_WITH_ID_TOKEN_QUERY = gql`
  query loginWithIdToken($token: String!) {
    loginWithIdToken(token: $token)
  }
`;

const LOGIN_WITH_SSO_TOKEN_QUERY = gql`
  query loginWithSsoToken($token: String!) {
    loginWithSsoToken(token: $token)
  }
`;

const GET_VIEW_AS_USER_TOKEN_QUERY = gql`
  query getViewAsUserToken($userId: String!) {
    getViewAsUserToken(userId: $userId)
  }
`;

const REGISTER_BROWSER_QUERY = gql`
  mutation registerBrowser {
    registerBrowser
  }
`;

export type LoginQueryResult = ApolloQueryResult<{ login: string }>;
export type LoginWithOtpTokenQueryResult = { loginWithOtpToken: string };
export type RenewTokenQueryResult = ApolloQueryResult<{ renew: string }>;
export type LoginWithIdTokenQueryResult = ApolloQueryResult<{ loginWithIdToken: string }>;
export type LoginWithSsoTokenQueryResult = ApolloQueryResult<{ loginWithSsoToken: string }>;
export type GetViewAsUserTokenQueryResult = ApolloQueryResult<{ getViewAsUserToken: string }>;

export const AuthService = {
  login: ({ email, password, deviceId }: { deviceId?: string; email: string; password: string }): Promise<LoginQueryResult> =>
    client.query(LOGIN_QUERY, { email, password, deviceId }, { fetchPolicy: "network-only" }),
  registerBrowser: () => client.mutate(REGISTER_BROWSER_QUERY),
  renewToken: (): Promise<RenewTokenQueryResult> => client.query(RENEW_TOKEN_QUERY),
  loginWithIdToken: (token: string): Promise<LoginWithIdTokenQueryResult> => client.query(LOGIN_WITH_ID_TOKEN_QUERY, { token }, { fetchPolicy: "network-only" }),
  loginWithSsoToken: (token: string): Promise<LoginWithSsoTokenQueryResult> => client.query(LOGIN_WITH_SSO_TOKEN_QUERY, { token }, { fetchPolicy: "network-only" }),
  getViewAsUserToken: (userId: string): Promise<GetViewAsUserTokenQueryResult> => client.query(GET_VIEW_AS_USER_TOKEN_QUERY, { userId }, { fetchPolicy: "network-only" }),
};
