import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  IconName,
  Modal,
  Size,
  DefaultDropdownInput,
  DefaultInput,
  IconCommon,
  FilledButton,
  SimpleDropdown,
  StepperTitle,
  ComponentType,
  TextV2,
  FontFamily,
  FontSize,
  FontWeight,
  useTheme,
  DateRange,
} from "@technis/ui";
import { Event, isEmailValid } from "@technis/shared";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { displayToast } from "@redux/toast/toast.slice";

import moment from "moment-timezone";
import { ALL_EVENTS_BY_INSTALLATION_QUERY, AllEventsByInstallationQueryResult } from "@services/eventService";
import { InstallationService } from "@services/installationService";
import { UserData } from "@services/userService";

const emailInputsInitialState = [{ id: uuidv4(), value: "" }];

type InvitationEmailInputProps = {
  id: string;
  value: string;
  onChangeEmail: (id: string, value: string) => void;
};

const InvitationEmailInput = ({ id, value, onChangeEmail }: InvitationEmailInputProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    onChangeEmail(id, newValue);
  };

  return <DefaultInput className="form-input" width="100%" value={value} onChange={onChange} icon={<IconCommon name={IconName.ENVELOPE} size={Size.LARGE} />} />;
};

type InvitationModalProps = {
  isModalVisible: boolean;
  onCloseModal: () => void;
  installationId: number;
  user?: UserData;
};

export const SupportDataModal = ({ installationId, isModalVisible, onCloseModal, user }: InvitationModalProps) => {
  const dispatch = useDispatch();
  const themeColors = useTheme().theme.colors;
  const defaultDateBegin = moment().subtract(1, "days").startOf("day").valueOf();
  const defaultDateEnd = moment().endOf("day").valueOf();

  const { data } = useQuery<AllEventsByInstallationQueryResult>(ALL_EVENTS_BY_INSTALLATION_QUERY, { variables: { installationId } });
  const allEvents = data?.allEventsByInstallation || [];

  const [isEventDropdownOpen, setIsEventDropdownOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Pick<Event, "id" | "name" | "dateBegin" | "dateEnd">>();
  const [emailInputs, setEmailInputs] = useState(emailInputsInitialState);

  const [dateBegin, setDateBegin] = useState(defaultDateBegin);
  const [dateEnd, setDateEnd] = useState(defaultDateEnd);

  useEffect(() => {
    if (!!data) {
      setSelectedEvent(allEvents[0]);
      setEmailInputs([{ id: uuidv4(), value: user?.email ?? "" }]);
    }
  }, [data, user]);

  const handleSetDateBegin = (dateBegin: number) => {
    setDateBegin(dateBegin);
  };
  const handleSetDateEnd = (dateEnd: number) => {
    setDateEnd(dateEnd);
  };

  const handleEventDropdownTriggerClick = (isOpen: boolean) => {
    setIsEventDropdownOpen(isOpen);
  };

  const handleEventDropdownClick = (event: Pick<Event, "id" | "name" | "dateBegin" | "dateEnd">) => {
    setSelectedEvent(event);
  };

  const onChangeEmail = (id: string, value: string) => {
    setEmailInputs(emailInputs.map((input) => (input.id === id ? { ...input, value } : input)));
  };

  const onClose = () => {
    onCloseModal();
    setSelectedEvent(undefined);
    setEmailInputs(emailInputsInitialState);
    setDateBegin(defaultDateBegin);
    setDateEnd(defaultDateEnd);
  };

  const onClickSend = async () => {
    try {
      const eventId = selectedEvent?.id;
      if (!eventId) throw new Error("Event id undefined");
      const supportDataRequest = emailInputs.map(({ value }) => ({
        eventId,
        email: value,
        begin: dateBegin,
        end: dateEnd,
      }));

      await Promise.all(supportDataRequest.map((invitation) => InstallationService.sendSupportData(invitation)));
      onClose();
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.installation.createSupportDataRequestSuccessMessage),
          variant: ComponentType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.installation.createSupportDataRequestErrorMessage),
          variant: ComponentType.ERROR,
        }),
      );
    }
  };

  const eventOptions = allEvents.map((event) => ({
    caption: event.name,
    onClick: () => handleEventDropdownClick(event),
  }));

  const emailInputsMap = emailInputs.map(({ id, value }) => <InvitationEmailInput key={id} id={id} value={value} onChangeEmail={onChangeEmail} />);

  const isValidInvitation = selectedEvent?.id && emailInputs.every((emailInput) => isEmailValid(emailInput.value));

  return (
    <Modal
      actionsElement={
        <div className="support-data-modal-footer">
          <FilledButton onClick={onClickSend} text={i18n.t(translation.installation.send)} size={Size.LARGE} disabled={!isValidInvitation} />
        </div>
      }
      headerElement={<StepperTitle title={i18n.t(translation.search.supportData)} iconName={IconName.ENVELOPE} />}
      size={Size.LARGE}
      shown={isModalVisible}
      onClose={onClose}
    >
      <div className="form-input-container">
        <div className="daterange-header">
          <TextV2 color={themeColors.OLD_BASE_40} fontFamily={FontFamily.LATO} fontSize={FontSize.SM} fontWeight={FontWeight.SEMIBOLD}>
            {i18n.t(translation.installation.dateRange)}
          </TextV2>
        </div>
        <div className="daterange-container">
          <DateRange endDate={dateEnd} startDate={dateBegin} setEndDate={handleSetDateEnd} setStartDate={handleSetDateBegin} />
        </div>
      </div>
      <div className="form-input-container">
        <div className="emails-header">
          <TextV2 color={themeColors.OLD_BASE_40} fontFamily={FontFamily.LATO} fontSize={FontSize.SM} fontWeight={FontWeight.SEMIBOLD}>
            {i18n.t(translation.users.email)}
          </TextV2>
        </div>
        {emailInputsMap}
      </div>
      <div className="form-input-container">
        <SimpleDropdown
          options={eventOptions}
          triggerElement={
            <DefaultDropdownInput label={i18n.t(translation.installation.event)} className="form-input" isOpen={isEventDropdownOpen} selectedOption={selectedEvent?.name || ""} />
          }
          onTriggerClick={handleEventDropdownTriggerClick}
        />
      </div>
    </Modal>
  );
};
