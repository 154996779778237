import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ID, Installation } from "@technis/shared";
import { useTheme, SimpleDropdown, DefaultDropdownInput, Size, IconCommon, IconName, Theme } from "@technis/ui";
import { AllInstallationsQueryResult, ALL_INSTALLATIONS_QUERY } from "@services/installationService";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";

type InstallationBadgeProps = {
  installation: Pick<Installation, "id" | "name">;
  theme: Theme;
  onRemoveInstallation: (installationId: ID) => void;
};

const InstallationBadge = ({ installation, theme, onRemoveInstallation }: InstallationBadgeProps) => {
  const onClick = () => {
    onRemoveInstallation(installation.id);
  };

  return (
    <div className="badge-container" style={{ backgroundColor: theme.colors.PRIMARY_40 }}>
      <span className="badge-label" style={{ color: theme.colors.BASE_00 }}>
        {installation.name}
      </span>
      <IconCommon name={IconName.XMARK} size={Size.SMALL} color={theme.colors.BASE_00} onClick={onClick} />
    </div>
  );
};

type AccessesViewProps = {
  installationIds: ID[];
  setInstallationIds: React.Dispatch<React.SetStateAction<ID[]>>;
};

export const AccessesView = ({ installationIds, setInstallationIds }: AccessesViewProps) => {
  const { theme } = useTheme();
  const { data } = useQuery<AllInstallationsQueryResult>(ALL_INSTALLATIONS_QUERY);
  const allInstallations = data?.allInstallations || [];

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onTriggerClick = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const onOptionClick = (installationId: ID) => {
    setInstallationIds([...installationIds, installationId]);
  };

  const onRemoveInstallation = (installationId: ID) => {
    setInstallationIds(installationIds.filter((item) => item !== installationId));
  };

  const dropdownOptions = allInstallations
    .filter((installation) => !installationIds.includes(installation.id))
    .map((installation) => ({ caption: installation.name, onClick: () => onOptionClick(installation.id) }));
  const badges = installationIds.map((installationId) => {
    const installation = allInstallations.find((installation) => installation.id === installationId);

    return installation && <InstallationBadge key={installationId} installation={installation} theme={theme} onRemoveInstallation={onRemoveInstallation} />;
  });

  return (
    <>
      <SimpleDropdown
        options={dropdownOptions}
        triggerElement={
          <DefaultDropdownInput
            className="form-input"
            label={i18n.t(translation.users.installations)}
            placeholder={i18n.t(translation.users.installations)}
            isOpen={isOpen}
            selectedOption=""
          />
        }
        onTriggerClick={onTriggerClick}
      />
      <div className="badges">{badges}</div>
    </>
  );
};
