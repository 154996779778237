import { gql } from "@apollo/client";
import { User, Access, Right } from "@technis/shared";
import { client, Mutation } from "./index";

const userData = `
  id
  name
  lang
  email
  organizationIds
`;

export const ME_DATA_QUERY = gql`
  query userData {
    me {
      ${userData}
    }
  }
`;

export const USER_CONNECTION_QUERY = gql`
  query userConnection($searchValue: String) {
    userConnection(first: 10, filter: { searchValue: $searchValue }) {
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          email
          name
          organizationIds
        }
        cursor
      }
    }
  }
`;

export const USER_BY_ID_QUERY = gql`
  query userById($userId: String!) {
    userById(userId: $userId) {
      id
      name
      email
      phone
      organizationIds
      lang
      theme
      isEmailVerified
      isPhoneVerified
      otpContact
      accessIds
      rightIds
      role
    }
  }
`;

export const MUTATE_USER = gql`
  mutation mutateUser($user: UserInput) {
    mutateUser(user: $user) {
      ${userData}
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;

export type Lang = "en";

export interface UserData {
  id: string;
  email: string;
  name: string;
  lang: Lang;
  organizationIds: number[];
}

export type MeQueryData = {
  me: UserData;
};

export type UserConnectionEdge = {
  node: {
    id: string;
    email: string;
    name: string;
    organizationIds: number[];
  };
  cursor: string;
};

export type UserConnectionQueryResult = {
  userConnection: {
    count: number;
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: UserConnectionEdge[];
  };
};

export type UserByIdQueryResult = {
  userById: User;
};

export type MutateUserResult = Mutation<{ mutateUser: User }>;

export type MutateUserInput = Pick<User, "id" | "name" | "email" | "phone" | "organizationIds" | "lang" | "otpContact" | "isEmailVerified" | "isPhoneVerified"> & {
  accesses: Omit<Access, "id">[];
  rights: Omit<Right, "id" | "userIds">[];
};

export const UserService = {
  mutateUser: (user: Omit<MutateUserInput, "password">): Promise<MutateUserResult> => client.mutate(MUTATE_USER, { user }),
  deleteUser: (id: string) => client.mutate(DELETE_USER, { id }),
};
