import React, { useCallback, useState } from "react";
import { DefaultTable, IconName } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { useOrganizationsSearch } from "@utils/useOrganisationsSearch";
import { getOrganizationTableColumns, getOrganizationsRows } from "@utils/organizationsTableHelpers";
import { SearchWrapper } from "@components/searchHelpers";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE } from "@utils/utils";
import { OrganizationDataModal } from "./OrganizationDataModal";

export const OrganizationsSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [isOrganizationDataModalVisible, setIsOrganizationDataModalVisible] = useState(false);
  const [organizationId, setOrganizationId] = useState<number>(0);

  const { organizations, refetchOrganizations, loading } = useOrganizationsSearch();

  const debouncer = useCallback(
    debounce((newValue: string) => {
      refetchOrganizations({ searchValue: newValue });
      setIsDebouncing(false);
    }, DEFAULT_DEBOUNCE),
    [refetchOrganizations],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    setIsDebouncing(true);
    debouncer(newValue);
  };

  const onCloseOrganizationDataModal = () => {
    setIsOrganizationDataModalVisible(false);
  };

  const onClickOrganizationData = (OrganizationId: number) => {
    setOrganizationId(OrganizationId);
    setIsOrganizationDataModalVisible(true);
  };

  const organizationTableColumns = getOrganizationTableColumns(onClickOrganizationData);

  return (
    <SearchWrapper iconName={IconName.BUILDING} title={i18n.t(translation.search.organizations)} onChange={onChange} searchValue={searchValue}>
      {searchValue && !isDebouncing && !loading && (
        <DefaultTable columns={organizationTableColumns} rows={getOrganizationsRows(organizations, organizationTableColumns)} className="results-table" />
      )}
      <OrganizationDataModal isModalVisible={isOrganizationDataModalVisible} onCloseModal={onCloseOrganizationDataModal} organizationId={organizationId} />
    </SearchWrapper>
  );
};
