export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  USERS: "/users",
  SSO_CALLBACK: "/sso-callback",
  SSO_POPUP_CALLBACK: "/sso-popup-callback",
  INSTALLATIONS: "/installations",
  ORGANIZATIONS: "/organizations",
  NOT_FOUND: "/not-found",
  ALL: "*",
};
