import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  IconName,
  Modal,
  Size,
  DefaultDropdownInput,
  DefaultInput,
  IconCommon,
  FlatButton,
  FilledButton,
  SimpleDropdown,
  StepperTitle,
  ComponentType,
  FlatIconButton,
  TextV2,
  FontFamily,
  FontSize,
  FontWeight,
  useTheme,
  LegacyTopBar,
} from "@technis/ui";
import { ID, isEmailValid, Organization, RightType, UserRoles } from "@technis/shared";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { AllOrganizationsQueryResult, ALL_ORGANIZATIONS_QUERY } from "@services/organizationService";
import { InvitationService } from "@services/invitationService";
import { displayToast } from "@redux/toast/toast.slice";
import { RightsView } from "./RightsView";
import { AccessesView } from "./AccessesView";
import { objectToStr } from "../../utils/utils";
import { TABS, Dashboard, LanguageDropdownOption, supportedLanguages, getUserFormTabs } from "./common";

const emailInputsInitialState = [{ id: uuidv4(), value: "" }];

type InvitationEmailInputProps = {
  id: string;
  value: string;
  onChangeEmail: (id: string, value: string) => void;
};

const InvitationEmailInput = ({ id, value, onChangeEmail }: InvitationEmailInputProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    onChangeEmail(id, newValue);
  };

  return <DefaultInput className="form-input" width="100%" value={value} onChange={onChange} icon={<IconCommon name={IconName.ENVELOPE} size={Size.LARGE} />} />;
};

type InvitationModalProps = {
  isModalVisible: boolean;
  isBulkInvitation: boolean;
  onCloseModal: () => void;
};

export const InvitationModal = ({ isModalVisible, isBulkInvitation, onCloseModal }: InvitationModalProps) => {
  const dispatch = useDispatch();
  const themeColors = useTheme().theme.colors;
  const [selectedTabIndex, setSelectedTabIndex] = useState(TABS.GENERAL);
  const tabs = getUserFormTabs(setSelectedTabIndex);

  const { data } = useQuery<AllOrganizationsQueryResult>(ALL_ORGANIZATIONS_QUERY);
  const allOrganizations = data?.allOrganizations || [];

  const [selectedDashboard, setSelectedDashboard] = useState(Dashboard.LEGACY);
  const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [isOrganizationDropdownOpen, setIsOrganizationDropdownOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Pick<Organization, "id" | "name">>();
  const [emailInputs, setEmailInputs] = useState(emailInputsInitialState);
  const [selectedLanguage, setSelectedLanguage] = useState(supportedLanguages[0]);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [rightKeys, setRightKeys] = useState<string[]>([]);
  const [installationIds, setInstallationIds] = useState<ID[]>([]);

  const handleDashboardDropdownClick = (option: Dashboard) => {
    setSelectedDashboard(option);
  };

  const handleDashboardDropdownTriggerClick = (isOpen: boolean) => {
    setIsDashboardDropdownOpen(isOpen);
  };

  const handleOrganizationDropdownClick = (organization: Pick<Organization, "id" | "name">) => {
    setSelectedOrganization(organization);
  };

  const handleOrganizationDropdownTriggerClick = (isOpen: boolean) => {
    setIsOrganizationDropdownOpen(isOpen);
  };

  const onChangeEmail = (id: string, value: string) => {
    setEmailInputs(emailInputs.map((input) => (input.id === id ? { ...input, value } : input)));
  };

  const onAddEmailInput = () => {
    setEmailInputs(emailInputs.concat({ id: uuidv4(), value: "" }));
  };

  const handleLanguageDropdownClick = (option: LanguageDropdownOption) => {
    setSelectedLanguage(option);
  };

  const handleLanguageDropdownTriggerClick = (isOpen: boolean) => {
    setIsLanguageDropdownOpen(isOpen);
  };

  const onClickNext = () => {
    if (selectedTabIndex === TABS.GENERAL) setSelectedTabIndex(TABS.RIGHTS);
    if (selectedTabIndex === TABS.RIGHTS) setSelectedTabIndex(TABS.ACCESSES);
  };

  const onClose = () => {
    onCloseModal();
    setSelectedTabIndex(TABS.GENERAL);
    setSelectedDashboard(Dashboard.LEGACY);
    setSelectedOrganization(undefined);
    setEmailInputs(emailInputsInitialState);
    setSelectedLanguage(supportedLanguages[0]);
    setRightKeys([]);
    setInstallationIds([]);
  };

  const onClickSendInvitation = async () => {
    try {
      const organizationId = selectedOrganization?.id;
      const rights = {
        organizationId,
        rightsRaw: objectToStr(Object.values(RightType).reduce((acc, key) => ({ ...acc, [key]: rightKeys.includes(key) }), {})),
      };
      const accesses = installationIds.map((installationId) => ({ installationId, interfaceId: null, eventIds: [], zoneIds: [] }));
      const invitaions = emailInputs.map(({ value }) => ({
        organizationId,
        email: value,
        lang: selectedLanguage.value,
        role: UserRoles.USER,
        rights,
        accesses,
      }));

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await Promise.all(invitaions.map((invitation) => InvitationService.createInvitation(invitation))); // TODO: fix InvitationInput type in shared
      onClose();
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.createInvitationSuccessMessage), // TODO: add message for bulk invitation
          variant: ComponentType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.createInvitationErrorMessage),
          variant: ComponentType.ERROR,
        }),
      );
    }
  };

  const dashboardOptions = Object.values(Dashboard).map((dashboard) => ({
    caption: dashboard,
    onClick: () => handleDashboardDropdownClick(dashboard),
  }));
  const organizationOptions = allOrganizations.map((organization) => ({
    caption: organization.name,
    onClick: () => handleOrganizationDropdownClick(organization),
  }));
  const languageOptions = supportedLanguages.map((option) => ({
    caption: option.label,
    onClick: () => handleLanguageDropdownClick(option),
  }));
  const emailInputsMap = emailInputs.map(({ id, value }) => <InvitationEmailInput key={id} id={id} value={value} onChangeEmail={onChangeEmail} />);

  const isValidInvitation = selectedOrganization?.id && emailInputs.every((emailInput) => isEmailValid(emailInput.value));

  return (
    // @ts-ignore // TODO: add children to ModalProps interface in technis-ui
    <Modal
      actionsElement={
        <div className="modal-footer">
          <FlatButton onClick={onClose} text={i18n.t(translation.users.cancel)} size={Size.LARGE} className="cancel-button" />
          {selectedDashboard !== Dashboard.LEGACY || selectedTabIndex === TABS.ACCESSES ? (
            <FilledButton
              onClick={onClickSendInvitation}
              text={i18n.t(isBulkInvitation ? translation.users.sendInvitations : translation.users.sendInvitation)}
              size={Size.LARGE}
              disabled={!isValidInvitation}
            />
          ) : (
            <FilledButton onClick={onClickNext} text={i18n.t(translation.users.next)} size={Size.LARGE} />
          )}
        </div>
      }
      headerElement={<StepperTitle title={i18n.t(translation.users.invitationModalTitle)} iconName={IconName.USER_GROUP} />}
      size={Size.LARGE}
      shown={isModalVisible}
      onClose={onClose}
    >
      <LegacyTopBar tabs={tabs} selectedTabIndex={selectedTabIndex} setSelectedItemIndex={setSelectedTabIndex} />
      {selectedTabIndex === TABS.GENERAL && (
        <div className="modal-tab-content">
          <div className="form-input-container">
            <SimpleDropdown
              options={dashboardOptions}
              triggerElement={
                <DefaultDropdownInput label={i18n.t(translation.users.dashboard)} className="form-input" isOpen={isDashboardDropdownOpen} selectedOption={selectedDashboard} />
              }
              onTriggerClick={handleDashboardDropdownTriggerClick}
            />
          </div>
          <div className="form-input-container">
            <SimpleDropdown
              options={organizationOptions}
              triggerElement={
                <DefaultDropdownInput
                  className="form-input"
                  label={i18n.t(translation.users.organization)}
                  placeholder={i18n.t(translation.users.organization)}
                  isOpen={isOrganizationDropdownOpen}
                  selectedOption={selectedOrganization?.name || ""}
                />
              }
              onTriggerClick={handleOrganizationDropdownTriggerClick}
            />
          </div>
          <div className="form-input-container">
            <div className="emails-header">
              <TextV2 color={themeColors.OLD_BASE_40} fontFamily={FontFamily.LATO} fontSize={FontSize.SM} fontWeight={FontWeight.SEMIBOLD}>
                {i18n.t(isBulkInvitation ? translation.users.emails : translation.users.email)}
              </TextV2>
              {isBulkInvitation && <FlatIconButton iconName={IconName.PLUS} iconSize={Size.MEDIUM} onClick={onAddEmailInput} />}
            </div>
            {emailInputsMap}
          </div>
          <div className="form-input-container">
            <SimpleDropdown
              options={languageOptions}
              triggerElement={
                <DefaultDropdownInput className="form-input" label={i18n.t(translation.users.language)} isOpen={isLanguageDropdownOpen} selectedOption={selectedLanguage.label} />
              }
              onTriggerClick={handleLanguageDropdownTriggerClick}
            />
          </div>
        </div>
      )}
      {selectedTabIndex === TABS.RIGHTS && (
        <div className="modal-tab-content">
          <RightsView rightKeys={rightKeys} setRightKeys={setRightKeys} />
        </div>
      )}
      {selectedTabIndex === TABS.ACCESSES && (
        <div className="modal-tab-content">
          <AccessesView installationIds={installationIds} setInstallationIds={setInstallationIds} />
        </div>
      )}
    </Modal>
  );
};
