import React from "react";
import { Logo, OutlineButton } from "@technis/ui";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ROUTES } from "@routes/config";
import { logout } from "@redux/auth/auth.slice";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";

export const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickLogo = () => {
    navigate(ROUTES.ROOT);
  };

  const onClickLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="main-layout">
      <div className="header">
        <Logo className="logo" onClick={onClickLogo} />
        <OutlineButton text={i18n.t(translation.login.logout)} onClick={onClickLogout} />
      </div>
      <Outlet />
    </div>
  );
};
