import { useQuery, useLazyQuery } from "@apollo/client";
import { UserConnectionQueryResult, USER_CONNECTION_QUERY } from "@services/userService";
import { AllOrganizationsQueryResult, ALL_ORGANIZATIONS_QUERY } from "@services/organizationService";

export const useUsersSearch = () => {
  const { data: allOrganizationsData, loading } = useQuery<AllOrganizationsQueryResult>(ALL_ORGANIZATIONS_QUERY);
  const [getUsers, { data, refetch, loading: fetchLoading }] = useLazyQuery<UserConnectionQueryResult>(USER_CONNECTION_QUERY);

  const allOrganizations = allOrganizationsData?.allOrganizations;
  const edges =
    data?.userConnection.edges.map((edge) => ({
      ...edge.node,
      userId: edge.node.id, // TODO: replace this workaround for actions in table
      organization: edge.node.organizationIds
        .map((organizationId) => allOrganizations?.find((organization) => organization.id === organizationId))
        .filter((organization) => organization)
        .map((organization) => organization?.name)
        .join(", "), // TODO: Add placeholder if no organizations
    })) || [];

  return { users: edges, loading, fetchLoading, refetchUsers: refetch };
};
