import React, { useMemo } from "react";
import { CheckboxState, CheckboxGroup, CheckboxElementProps } from "@technis/ui";
import { RightType } from "@technis/shared";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";

const SELECT_ALL_OPTION = "selectAll";
const ALL_RIGHTS = Object.values(RightType);

type RightsViewProps = {
  rightKeys: string[];
  setRightKeys: React.Dispatch<React.SetStateAction<string[]>>;
};

export const RightsView = ({ rightKeys, setRightKeys }: RightsViewProps) => {
  const selectAllCheckbox = useMemo(
    () => ({
      id: SELECT_ALL_OPTION,
      value: SELECT_ALL_OPTION,
      label: i18n.t(translation.users.selectAll),
      checked: ALL_RIGHTS.length === rightKeys.length,
    }),
    [rightKeys],
  );
  const checkboxElements = useMemo(
    () =>
      ALL_RIGHTS.map((rightKey) => ({
        id: rightKey,
        value: rightKey,
        label: i18n.t(`users.${rightKey}`),
        checked: rightKeys.includes(rightKey),
      })),
    [rightKeys],
  );

  const onChange = (checkBoxState: CheckboxState, element: any /*CheckboxElementProps*/) => {
    const isChecked = checkBoxState === CheckboxState.CHECKED;

    if (element.id === SELECT_ALL_OPTION) {
      setRightKeys(isChecked ? ALL_RIGHTS : []);
    } else {
      setRightKeys(isChecked ? [...rightKeys, element.id] : rightKeys.filter((item) => item !== element.id));
    }
  };

  return <CheckboxGroup groupLabel={i18n.t(translation.users.rights)} checkboxElements={[selectAllCheckbox, ...checkboxElements]} onChange={onChange} />;
};
