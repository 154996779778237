import { ErrorResponse } from "@apollo/client/link/error";
import { ComponentType, Icon, IconTypes, LoginForm, Logo, LOGO_TYPE, useTheme } from "@technis/ui";
import { AuthErrors, OtpContactType, TokenType } from "@technis/shared";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { AuthService, SSO_OPTIONS } from "@services/authService";
import { allApolloErrorsToString } from "@api/utils";
import { loginSuccess, saveToken } from "@redux/auth/auth.slice";
import { displayToast } from "@redux/toast/toast.slice";
import { BackgroundBubbles } from "@components/BackgroundBubbles";
import { translation } from "@lang/translation";
import { i18n } from "@lang/i18n";
import { RootState } from "@store/rootReducer";
import { ROUTES } from "@routes/config";
import { LoginOtpForm } from "./LoginOtpForm";
import { BrowserTokenStorage } from "@utils/localStorage";
import { UserManager } from "oidc-client-ts";

interface LocationState {
  from: {
    pathname: string;
  };
}

export const Login = () => {
  const { themeVariant } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as LocationState)?.from?.pathname || "/";
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpFormVisible, setIsOtpFormVisible] = useState(false);
  const [userData, setUserData] = useState<{ otpContact: OtpContactType; email: string; password: string }>();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) navigate(ROUTES.ROOT, { replace: true });
  }, [isLoggedIn, navigate]);

  const onSubmit = async ({ email, password }: { email: string; password: string }) => {
    const browserId = BrowserTokenStorage.get(email) || undefined;
    try {
      setIsLoading(true);
      const response = await AuthService.login({ email, password, deviceId: browserId });
      const token = response.data.login;

      const {
        claims: { tokenType, otpContact },
      }: {
        claims: {
          tokenType: TokenType;
          otpContact?: OtpContactType;
        };
      } = jwtDecode(token);

      if (tokenType === TokenType.OTP && otpContact) {
        dispatch(saveToken(token));
        setUserData({ otpContact, email, password });
        setIsOtpFormVisible(true);
      } else {
        dispatch(loginSuccess(token));
        navigate(from, { replace: true });
      }
    } catch (error) {
      const parsedError = allApolloErrorsToString(error as ErrorResponse);

      if (parsedError === AuthErrors.BAD_CREDENTIALS) {
        dispatch(
          displayToast({
            id: uuidv4(),
            text: i18n.t(translation.login.wrongCredentials),
            variant: ComponentType.ERROR,
          }),
        );
      } else {
        dispatch(
          displayToast({
            id: uuidv4(),
            text: i18n.t(translation.login.commonError),
            variant: ComponentType.ERROR,
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    setIsOtpFormVisible(false);
  };

  const resendCode = async () => {
    if (!userData) return;
    await onSubmit(userData);
  };

  const trySilentSignIn = async (ssoManager: UserManager) => {
    try {
      return await ssoManager.signinSilent();
    } catch (e) {
      return null;
    }
  };

  const handleSsoSignIn = async () => {
    try {
      const ssoManager = new UserManager(SSO_OPTIONS);
      let user = await trySilentSignIn(ssoManager);
      if (!user) user = await ssoManager.signinPopup();
      const response = await AuthService.loginWithSsoToken(user.access_token);
      const token = response.data.loginWithSsoToken;

      dispatch(loginSuccess(token));
      navigate(from, { replace: true });
    } catch (error) {
      dispatch(
        displayToast({
          id: uuidv4(),
          text: "The user cannot be found.",
          variant: ComponentType.ERROR,
        }),
      );
    }
  };

  return (
    <div className="login">
      <BackgroundBubbles className="loginBackground" themeVariant={themeVariant} />
      <div className="loginElements">
        <Logo className="logo" />
        <div className="form">
          {isOtpFormVisible ? (
            <LoginOtpForm email={userData?.email} otpContact={userData?.otpContact || OtpContactType.EMAIL} goBack={goBack} resendCode={resendCode} />
          ) : (
            <>
              <LoginForm isLoading={isLoading} onSubmit={onSubmit} />
              <div className="googleButtonContainer" style={{ padding: "0 5px" }}>
                <button type="button" className="googleButton" onClick={handleSsoSignIn} style={{ width: "200px" }}>
                  <Icon size={38} icon={IconTypes.LOCK} />
                  <p className="googleButtonLabel">Sign in with SSO</p>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="copyright">
        {i18n.t(translation.login.copyright)} <Logo size={47} type={LOGO_TYPE.MINI} className="miniLogo" />
      </div>
    </div>
  );
};
