import { api } from "../../api/apollo";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../index";

export interface AuthState {
  token: string | undefined;
  isLoggedIn: boolean;
  loginErrorMessage: string | undefined;
}

export const initialState: AuthState = {
  token: undefined,
  isLoggedIn: false,
  loginErrorMessage: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveToken: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    loginSuccess: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem("token", payload);
      state.isLoggedIn = true;
      state.loginErrorMessage = undefined;
      state.token = payload;
    },
    loginError: (state, { payload }: PayloadAction<string>) => {
      state.isLoggedIn = false;
      state.loginErrorMessage = payload;
    },
    logout: () => {
      signOut(firebaseAuth).catch(() => {
        console.log("error");
      });
      localStorage.removeItem("token");
      api.client?.resetStore();
      return {
        ...initialState,
      };
    },
  },
});

export const { saveToken, loginSuccess, loginError, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
