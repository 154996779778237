import { Indexable } from "@technis/shared";
import { default as i18nModule, TOptions } from "i18next";
import merge from "lodash/merge";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import en from "./en.json";
import fr from "./fr.json";
import de from "./de.json";
import it from "./it.json";
import es from "./es.json";
import { getPlural } from "./helper";

export interface TranslationOption {
  key: string | string[] | Indexable<string>;
  defaultValue?: string;
  count?: number;
  options?: TOptions;
}

const TRANSLATIONS = {
  en,
  fr,
  de,
  it,
  es,
};

const i18n = i18nModule;

i18n
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  })
  .then(() => {
    console.log("i18next initialized");
  });

export const addTranslation = (langKey: string, translation: object) => {
  const localTranslation = get(TRANSLATIONS, langKey, {});
  const combined = merge(localTranslation, translation);

  if (!isEmpty(combined)) {
    i18n.addResourceBundle(langKey, "translation", combined, true, true);
  }
};

export const addTranslations = (translations: object) => {
  Object.entries(translations).forEach(([lang, translation]) => addTranslation(lang, translation));
};

export const translate = (params: TranslationOption) => {
  const { count, defaultValue, key, options } = getPlural(params);
  return i18n.t(key ?? "", {
    ...options,
    defaultValue,
    count,
  });
};

export { i18n };
